// fix for img src not background-img

(function ($) {
    var Dropzone = function ($el, settings) {
        this.$dropzone = $el;
        var options = $.extend({
            size: 5,
            sendImage: $el.attr('data-send-func'),
            sendUrl: '/',
            deleteImage: $el.attr('data-delete-func'),
            deleteUrl: $el.attr('data-delete-url'),
            removing: true,
            validate: true,
            type: 'img', //button, audio,
            src: 'src' //  background
        }, {
            size: +$el.attr('data-size'),
            sendUrl: $el.attr('data-send-url')
        });

        options = $.extend(options, settings);

        var _this = this;

        // console.log('src', options.src)

        this.$dropzone
            .on('dragover', function () {
                $(this).addClass('hover');
            })
            .on('dragleave', function () {
                $(this).removeClass('hover');
            })
            .on('click', '.dropzone-remove', function (e) {
                e.stopPropagation();

                function removeDropStyle() {
                    _this.$dropzone.removeClass('dropped');

                    if (options.removing) {
                        _this.$dropzone.children('.dropzone-remove').remove();

                    }

                    if (options.type == 'img') {

                        if (options.src == 'src') {
                            _this.$dropzone
                                .find('img').attr('src', '')
                                .children(':not(input, img)').remove();

                        } else if (options.src == 'background') {
                            _this.$dropzone
                                .removeAttr('style')
                                .children(':not(input)').remove();
                        }

                        _this.$dropzone.prepend($('<span/>').append('Размер до ' + options.size + ' МБ<br/>(jpg, jpeg, png)'));


                    } else if (options.type == 'audio') {
                        _this.$dropzone
                            .removeAttr('style')
                            .children(':not(input)').remove();

                        _this.$dropzone.prepend($('<span/>').append('Размер до ' + options.size + ' МБ (MP3)'));
                        //_this.$dropzone.siblings('.dropzone-progress').css('right', '100%').text('0%');
                    }

                    _this.$dropzone.closest('.form-group').find('input').val('');
                };

                if (options.deleteUrl) {
                    $.ajax({
                        type: "POST",
                        url: options.deleteUrl,
                        data: data,
                        processData: false,
                        contentType: false,
                        cache: false,
                        success: function (response) {
                            if (options.deleteImage) {
                                window[options.deleteImage](response);
                            }

                            $(input).val('');
                            removeDropStyle();
                            _this.$dropzone.trigger('dropzone:remove');
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            console.log(xhr.status);
                            console.log(thrownError);
                        }
                    });
                } else {
                    removeDropStyle();
                    _this.$dropzone.trigger('dropzone:remove');
                }

                return false;
            })
            .on('change', 'input[type="file"]', function (e) {
                var input = this;
                var file = input.files[0];

                if (!file) {
                    return;
                }

                var t = true;

                _this.$dropzone
                    .removeClass('hover')
                    .find('.inp-err').remove();

                _this.$dropzone
                    .next('.inp-err').remove();

                if (options.type == 'img' || options.type == 'audio') {

                    if (options.src == 'src') {
                        _this.$dropzone.children(':not(input, .form-group, img)').remove();
                    } else if (options.src == 'background') {
                        _this.$dropzone.children(':not(input, .form-group)').remove();
                    }


                } else if (options.type == 'button') {
                    _this.$dropzone.next('.dropzone-filename').remove();
                }

                if (input.accept && $.inArray(file.type, input.accept.split(/, ?/)) == -1) {
                    if (options.validate) {
                        _this.$dropzone.append($('<span/>', {class: 'inp-err', text: 'Тип файла не поддерживается'}));
                    }
                    t = false;
                }

                if (file.size > (1024 * 1024 * options.size)) {
                    if (options.validate) {
                        if (options.type == 'button') {
                            _this.$dropzone.after($('<span/>', {class: 'inp-err', text: 'Большой размер файла'}));
                        } else {
                            _this.$dropzone.append($('<span/>', {class: 'inp-err', text: 'Большой размер файла'}));
                        }

                    }
                    t = false;
                }

                if (t) {
                    // $(input).inputCheck('errRemove');

                    _this.$dropzone.addClass('dropped');
                    $('.cropzone-remove').fadeIn('fast');

                    if (options.type == 'img' && !(/^image\/(jpg|png|jpeg|gif)$/i).test(file.type)) {
                        if (options.validate) {
                            if (options.type == 'button') {
                                _this.$dropzone.after($('<span/>', {
                                    class: 'inp-err help-block-error',
                                    text: 'bad format ' + file.type
                                }));
                            } else if (options.type == 'img') {
                                _this.$dropzone.append($('<span/>', {
                                    class: 'inp-err help-block-error',
                                    text: 'bad format ' + file.type
                                }));
                            }
                        }
                    } else {
                        var reader = new FileReader(file);

                        reader.readAsDataURL(file);

                        if (options.type == 'audio') {
                            var $progress = _this.$dropzone.parent().find('.dropzone-progress');

                            reader.onprogress = function (e) {
                                // evt is an ProgressEvent.
                                if (e.lengthComputable) {
                                    var percentLoaded = Math.round((e.loaded / e.total) * 100);
                                    // Increase the progress bar length.
                                    if (percentLoaded < 100) {
                                        $progress.css('right', 100 - percentLoaded + '%');
                                        $progress.text(percentLoaded + '%');
                                    }
                                }
                            };
                        }

                        reader.onload = function (e) {
                            if (options.type == 'img') {
                                var zoneImg = new Image;
                                zoneImg.src = e.target.result;

                                if (options.src == 'src') {
                                    console.log('src')
                                    _this.$dropzone.find('img').attr('src', zoneImg.src);
                                } else if (options.src == 'background') {
                                    console.log('background')
                                    _this.$dropzone.css('background-image', 'url(' + zoneImg.src + ')');
                                }

                            } else if (options.type == 'button') {
                                _this.$dropzone.after($('<div/>', {class: 'dropzone-filename', text: file.name}))
                            } else if (options.type == 'audio') {
                                $progress.css('right', '100%').text('');
                                _this.$dropzone.prepend($('<span/>', {text: '100%'}));
                            }

                            if (options.removing && !_this.$dropzone.find('.dropzone-remove').length) {

                                if (_this.$dropzone.hasClass('cropzone remove')) {
                                    _this.$dropzone.append($('<div/>', {class: 'glyphicon glyphicon-remove cropzone-remove'}).fadeIn('slow'));
                                } else {
                                    _this.$dropzone.append($('<div/>', {class: 'glyphicon glyphicon-remove dropzone-remove'}));
                                }
                            }
                            //event trigger
                            _this.$dropzone.trigger('dropzone:load', [file.name]);

                            if (options.sendUrl != "/") {
                                var data = new FormData();
                                data.append($(input).attr('name'), file);

                                $.ajax({
                                    type: "POST",
                                    url: options.sendUrl,
                                    data: data,
                                    processData: false,
                                    contentType: false,
                                    cache: false,
                                    success: function (response) {
                                        _this.$dropzone.trigger('dropzone:success');

                                        if (window[options.sendImage]) {
                                            window[options.sendImage](response, _this.$dropzone);
                                        }

                                        $(input).val('');
                                    },
                                    error: function (xhr, ajaxOptions, thrownError) {
                                        console.log(xhr.status);
                                        console.log(thrownError);
                                    }
                                });
                            }
                        };
                    }
                } else {
                    // $(input).inputCheck('makeError');
                    $(input).val('');
                }
            });
    };

    $.fn.dropzone = function (methodOrOptions) {
        function init() {
            var $el = $(this);

            $el.data('dropzone', new Dropzone($el, methodOrOptions));
        }

        return this.each(init);
    };

    var DropzoneMulti = function ($el, settings) {
        this.$dropzoneMulti = $el;
        var options = $.extend({
            size: 5,
            sendImage: $el.attr('data-send-func'),
            sendUrl: '/',
            deleteImage: $el.attr('data-delete-func'),
            deleteUrl: $el.attr('data-delete-url'),
            sortFunc: $el.attr('data-sort-func'),
            filesCount: 10,
            data: {
                id: $el.attr('data-gallery-num')
            },
            $galleryCover: $el
            //removing: true,
            //validate: true
            //type: 'img' //button
        }, {
            size: +$el.attr('data-size'),
            sendUrl: $el.attr('data-send-url'),
            filesCount: $el.attr('data-files-count'),
            $galleryCover: $('#' + $el.attr('gallery-cover'))
            // data: {}
        });

        options = $.extend(options, settings);

        var _this = this;

        options.$galleryCover.sortable({
            axis: 'x',
            items: '> div',
            stop: function () {
                if (options.sortFunc) {
                    window[options.sortFunc](options.$galleryCover);
                }
            }
        });

        options.$galleryCover.on('click', '.dropzone-remove', function (e) {
            e.stopPropagation();

            var $dropOne = $(this).closest('.gallery-item');

            function removeDropStyle() {
                $dropOne.remove();

                if (!options.$galleryCover.find('.gallery-item').length) {
                    _this.$dropzoneMulti
                        .removeClass('dropped');
                    //.removeAttr('style');
                    //.children(':not(input)').remove();

                    //_this.$dropzoneMulti.prepend($('<span/>').append('Размер до ' + options.size + ' МБ<br/>(jpg, jpeg, png)'));
                }
            };

            if (options.deleteUrl) {
                $.ajax({
                    type: "POST",
                    url: options.deleteUrl + "?id=" + $dropOne.attr('data-id'),
                    cache: false,
                    success: function (response) {
                        if (options.deleteImage) {
                            window[options.deleteImage]($dropOne.attr('data-id'), options.$galleryCover);
                        }

                        //$dropOne.closest('.dropzone').find('input[type="file"]').val('');
                        removeDropStyle();
                        _this.$dropzoneMulti.trigger('dropzone:remove');
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        console.log(xhr.status);
                        console.log(thrownError);
                    }
                });
            } else {
                removeDropStyle();
                _this.$dropzoneMulti.trigger('dropzone:remove');
                window[options.deleteImage]($dropOne.attr('data-id'), _this.$dropzoneMulti);
            }

            return false;
        });

        this.$dropzoneMulti
            .on('dragover', function () {
                $(this).addClass('hover');
            })
            .on('dragleave', function () {
                $(this).removeClass('hover');
            })
            .on('change', 'input[type="file"]', function (e) {
                var input = this;
                var file = input.files[0];

                if (!file) {
                    return;
                }

                var fileList = [];
                var readerList = [];
                var listCount = 0;

                var t = true;

                _this.$dropzoneMulti
                    .removeClass('hover');
                //.children('span').remove();

                _this.$dropzoneMulti.siblings('.inp-err').remove();

                // _this.$dropzoneMulti.($('<span/>', {class: 'inp-err', text: 'Файл ' + file.name + ': большой размер'}));

                for (listCount in input.files) {
                    file = input.files[listCount];
                    if (typeof(file) == "object") {
                        fileList.push(file);

                        if (input.accept && $.inArray(file.type, input.accept.split(/, ?/)) == -1) {
                            _this.$dropzoneMulti.after($('<span/>', {
                                class: 'inp-err',
                                text: 'Файл ' + file.name + ': тип ' + file.type + ' не поддерживается'
                            }));
                            t = false;
                        }

                        if (file.size > (1024 * 1024 * options.size)) {
                            _this.$dropzoneMulti.after($('<span/>', {
                                class: 'inp-err',
                                text: 'Файл ' + file.name + ': большой размер'
                            }));
                            t = false;
                        }
                    }
                }

                if (t) {
                    // $(input).inputCheck('errRemove');

                    _this.$dropzoneMulti.addClass('dropped');

                    for (listCount in fileList) {
                        file = fileList[listCount];

                        if ((/^image\/(png|jpeg|jpg)$/i).test(file.type) && file.size <= (1024 * 1024 * options.size)) {
                            readerList[listCount] = new FileReader(file);
                            zoneImg = new Image;

                            readerList[listCount].readAsDataURL(file);

                            readerList[listCount].onload = function (e) {
                                zoneImg.src = e.target.result;

                                if (options.sendUrl) {
                                    var data = new FormData();
                                    data.append($(input).attr('name'), fileList[readerList.indexOf(this)]);

                                    if (options.data.id) {
                                        data.append('category', options.data.id);
                                    }

                                    $.ajax({
                                        type: "POST",
                                        url: options.sendUrl,
                                        data: data,
                                        processData: false,
                                        contentType: false,
                                        cache: false,
                                        success: function (response) {
                                            response = JSON.parse(response);

                                            if (response.status == 'done') {
                                                response = response.data;

                                                options.$galleryCover.append($('<div/>', {
                                                    class: 'gallery-item',
                                                    style: 'background-image: url(' + response.file + ')',
                                                    'data-id': response.id,
                                                    'data-link': response.file
                                                }).append(
                                                    $('<div/>', {class: 'dropzone-remove'})
                                                ));

                                                _this.$dropzoneMulti.trigger('dropzone:success');
                                                window[options.sendImage](response, _this.$dropzoneMulti);
                                            }

                                            $(input).val('');
                                        },
                                        error: function (xhr, ajaxOptions, thrownError) {
                                            console.log(xhr.status);
                                            console.log(thrownError);
                                        }
                                    });
                                } else {
                                    options.$galleryCover.append($('<div/>', {
                                        class: 'gallery-item',
                                        style: 'background-image: url(' + zoneImg.src + ')'
                                    }).append(
                                        $('<div/>', {class: 'dropzone-remove'})
                                    ));
                                }
                            };
                        } else {
                            _this.$dropzoneMulti.append($('<span/>', {
                                class: 'inp-err',
                                text: 'bad format ' + file.type
                            }));
                        }
                    }
                } else {
                    // $(input).inputCheck('makeError');
                    $(input).val('');
                }
            });
    };

    $.fn.dropzoneMulti = function (methodOrOptions) {
        function init() {
            var $el = $(this);

            $el.data('dropzoneMulti', new DropzoneMulti($el, methodOrOptions));
        }

        return this.each(init);
    };
})(jQuery);

$('.dropzone:not(.multi)').dropzone();

// $('.btn-crop-add').dropzone({
//     removing: false,
//     type: 'button'
// });


